import { getParamsIdx } from "helpers/urls";
import Head from "next/head";
import { useRouter } from "next/router";
import { CANONICAL_DOMAIN } from "../constants";

const CommonHead = () => {
  const router = useRouter();
  const paramsIdx = getParamsIdx(router.asPath);
  const canonicalURL = CANONICAL_DOMAIN + router.asPath.substring(0, paramsIdx);

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta property="og:url" content={canonicalURL} />
      <meta property="fb:app_id" content="529110858359377" />
      <link rel="canonical" href={canonicalURL} />

      {/* This needs to be here always. */}
      <meta
        name="facebook-domain-verification"
        content="xvyrlpiuo71swluwhp24tcwo908qwe"
      />

      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-53ZRP95KN3"
      />
      {/* See https://support.google.com/analytics/answer/9310895?hl=en#gtagjs-enable-basic&zippy=%2Cin-this-article 
      for how we set up multiple tags */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            // Non-Google ads (Google Analytics 4)
            gtag('config', 'G-53ZRP95KN3');
            // Google Ads (Universal Analytics)
            gtag('config', 'UA-205129023-1');
          `,
        }}
      />

      {/* Google Tag Manager (e.g., for TikTok) */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T3Q873H');
  `,
        }}
      ></script>

      {/* Facebook Pixel code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '491887035223775');
            fbq('track', 'PageView');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=491887035223775&ev=PageView&noscript=1"
            />
      `,
        }}
      ></noscript>

      {/* Mailchimp */}
      {/* main popup */}
      {/* <script id="mcjs" dangerouslySetInnerHTML={{
        __html: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/774e2cbce316a8a5441104552/27ff49120e6ce704689ecbb86.js");`
      }}/> */}

      {/* vercel popup */}
      {/* <script
        id="mcjs"
        dangerouslySetInnerHTML={{
          __html: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/774e2cbce316a8a5441104552/62dc726a3ef5e5463f7dc9c84.js");`,
        }}
      /> */}

      {/* Crisp chat */}
      {/* <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html:
            'window.$crisp=[];window.CRISP_WEBSITE_ID="d4ef0c6a-08f9-48aa-be95-2ff652b20872";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();',
        }}
      ></script> */}
    </Head>
  );
};
export default CommonHead;
