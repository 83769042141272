import { Theme } from "theme-ui";
import { commonTheme } from "./common";

export const appTheme: Theme = {
  ...commonTheme,
  // @ts-ignore
  section: {
    general: {
      px: [3, null, null, 5, null, 10],
    },
  },
};
