import { Box, Image, Heading, Text, ThemeUICSSObject, Flex } from "theme-ui";
import Modal from "react-modal";
import { imageURL } from "assets";
import { createContext, useEffect } from "react";
import AppStoreBadge from "./app-store-badge";
import PlayStoreBadge from "./play-store-badge";
import { useModalStyle } from "helpers/modals";

export const ModalContext = createContext({ toggle: () => {} });

const SignupModal = ({
  isOpen,
  toggle,
}: {
  isOpen: boolean;
  toggle: () => void;
}) => {
  const modalStyle = useModalStyle();
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={toggle}
      style={modalStyle}
    >
      <Image
        src={imageURL("oc-og-launched-image-sm.jpg")}
        alt="oneCoach online tennis lessons and drills"
      />
      <Heading as="h2" sx={styles.heading as ThemeUICSSObject}>
        Ready for the best tennis learning experience ever?
      </Heading>
      <Heading as="h3" sx={styles.subheading as ThemeUICSSObject}>
        {/* Enter your email to get early access to oneCoach. */}
        Try out oneCoach for free now.
      </Heading>

      <Flex style={{ alignItems: "center", justifyContent: "center" }}>
        <AppStoreBadge eventSuffix="signup_modal" />
        <PlayStoreBadge eventSuffix="signup_modal" />
      </Flex>

      {/* Mailchimp form */}
      {/* <link
        href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css"
        rel="stylesheet"
        type="text/css"
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
        #mc_embed_signup { background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
        #mc_embed_signup form { padding: 0; }
        #mc_embed_signup input.email { margin: 10px 4%; width: 80%; }
        #mc_embed_signup input.button { padding: 0 10px; width: 100%; background-color: #00B4D8; font-size: 18px; }
        #mc_embed_signup input.button:hover { background-color: #0077B6; }
        #mc_embed_signup_scroll { display: flex; flex-direction: column; align-items: center }
      `,
        }}
      />
      <div id="mc_embed_signup">
        <form
          action="https://onecoachapp.us5.list-manage.com/subscribe/post?u=774e2cbce316a8a5441104552&amp;id=cadb252e03"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate={true}
        >
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="your email"
              required={true}
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_774e2cbce316a8a5441104552_cadb252e03"
                tabIndex={-1}
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                value="Get Early Access"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                onClick={() => {
                  logEvent("Conversion", "early_access_signup");

                  //@ts-ignore
                  return window.gtag_report_conversion(undefined);
                }}
              />
            </div>
          </div>
        </form>
      </div> */}
      {/* <!--End mc_embed_signup--> */}

      <Text as="p" sx={styles.description as ThemeUICSSObject}>
        No credit card required.
        {/* Don't worry, we don't like spam either. */}
      </Text>
      <Box sx={styles.closeBtn as ThemeUICSSObject} onClick={toggle}>
        ⨉
      </Box>
    </Modal>
  );
};

const styles = {
  heading: {
    color: "heading_on_white",
    fontSize: ["18px", 24, 28, 34],
    textAlign: "center",
    lineHeight: 1.2,
    padding: [1, null, null, 5],
  },
  subheading: {
    color: "heading_on_white",
    fontSize: ["14px", null, null, "18px"],
    textAlign: "center",
    lineHeight: 1.2,
    padding: 2,
  },

  description: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: [1.85, null, null, 2, null, "2.2"],
    color: "heading_on_white",
    textAlign: "center",
    mb: "30px",
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "black",
    fontSize: ["24px", "30px"],
    padding: "0 10px",
    cursor: "pointer",
  },
};

export default SignupModal;
