export const CANONICAL_DOMAIN = "https://www.onecoachapp.com";
export const ASSET_URI_PREFIX =
  "https://onecoach-public.nyc3.cdn.digitaloceanspaces.com/web-assets";
export const APPSTORE_URL = "https://apps.apple.com/app/id1585731387";
export const PLAYSTORE_URL =
  "https://play.google.com/store/apps/details?id=com.onecoachapp.onecoach.android";
export const INSTA_URL = "https://www.instagram.com/onecoachapp";
export const TIKTOK_URL = "https://www.tiktok.com/@onecoach";
export const FB_URL = "https://www.facebook.com/oneCoachApp";
export const TWITTER_URL = "https://twitter.com/onecoachapp";

export const MONTHLY_COST = "7.49";

export const SEO_TITLE =
  "Personalized Online Tennis Lessons for Beginners and All Skill Levels | oneCoach";
export const SEO_DESC = `Access online tennis classes from pro instructors and coaches for just \$${MONTHLY_COST}/month. We offer step-by-step tennis lessons and drills for all skill levels, beginners to advanced, personalized for you. Try free, cancel anytime.`;
export const SEO_AUTHOR = "oneCoach";

export const SEO_META = [
  {
    name: "keywords",
    content: "tennis lesson, tennis coach, tennis class, tennis drills",
  },
  {
    property: "og:image",
    content: `${ASSET_URI_PREFIX}/oc-og-launched-image-sm.jpg`,
  },
  {
    property: "twitter:image",
    content: `${ASSET_URI_PREFIX}/oc-og-launched-image-square-sm.jpg`,
  },
  {
    property: "og:image:type",
    content: "image/jpeg",
  },
  {
    property: "og:image:width",
    content: "1024",
  },
  {
    property: "og:image:height",
    content: "1024",
  },
  {
    property: "og:image:alt",
    content: "oneCoach tennis lessons and drills for all skill levels app",
  },
  {
    name: `twitter:site`,
    content: "@oneCoachApp",
  },
];
