import React from "react";
import Head from "next/head";
import { SEO_AUTHOR, SEO_META } from "../constants";

export default function SEO({
  description,
  title,
  author = SEO_AUTHOR,
  meta = SEO_META,
}: {
  description: string;
  title: string;
  author?: string;
  meta?: any[];
}) {
  const metaData = [
    {
      name: `description`,
      content: description,
    },
    {
      name: `author`,
      content: author,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta ?? []);
  return (
    <Head>
      <title>{title}</title>
      {metaData.map(({ name, content, property }, i) => (
        <meta key={i} name={name} content={content} property={property} />
      ))}
    </Head>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
};
