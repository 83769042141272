import { Theme } from "theme-ui";

export const commonTheme: Theme = {
  colors: {
    text: "#343D48", // body color and primary color
    text_secondary: "#02073E", // secondary body color (dark blue)
    text_tertiary: "#6f8197", // light gray
    text_on_white: "#343D48",
    heading: "#244886", // primary heading color
    heading_secondary: "#0F2137", // heading color
    heading_on_white: "#0F2137",
    background: "#FFFFFF", // body background color
    background_secondary: "#F9FBFD", // secondary background color (light gray)
    background_tertiary: "#aaa", // secondary background color (light-dark gray)
    border_color: "#c3c3c3", // border color
    primary: "#00B4D8", // primary button and link color
    secondary: "#0077B6", // secondary color - can be used for hover states
    muted: "#7B8188", // muted color

    // highlight	a background color for highlighting text
    modes: {
      dark: {
        text: "#fff",
        text_secondary: "#eee",
        text_tertiary: "#ddd",
        text_on_white: "#343D48",
        heading: "#90E0EF",
        heading_secondary: "#CAF0F8",
        heading_on_white: "#0F2137",
        background: "#000",
        background_secondary: "#111",
        background_tertiary: "#222",
        border_color: "#c3c3c3",
        primary: "#00B4D8",
        secondary: "#0077B6",
        muted: "#111",
      },
    },
  },
  breakpoints: [
    "480px",
    "640px",
    "768px",
    "1024px",
    "1220px",
    "1366px",
    "1620px",
  ],
  fonts: {
    body: "'DM Sans', sans-serif",
    heading: "Poppins, 'DM Sans', sans-serif",
  },
  fontSizes: [12, 15, 16, 18, 20, 22, 24, 28, 32, 36, 42, 48, 52, 64],
  fontWeights: {
    body: "normal",
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.8,
    heading: 1.5,
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
    heading: "-0.5px",
  },
  space: [0, 5, 10, 15, 20, 25, 30, 50, 80, 100, 120, 150],
  // variants can use custom, user-defined names
  layout: {
    container: {
      maxWidth: [
        "100%",
        null,
        null,
        "780px",
        "1020px",
        "1200px",
        null,
        "1310px",
      ],
      px: [4, 6],
    },
    header: {
      color: "#02073E",
      fontWeight: "normal",
      py: 3,
      position: "absolute",
      width: "100%",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  text: {
    smaller: {
      fontSize: ["12px", null, 1, null, 2],
    },
    largeH1: {
      fontSize: 34,
    },
  },

  // variants for buttons
  buttons: {
    menu: {
      display: [null, null, "none"],
    }, // default variant for MenuButton
    // you can reference other values defined in the theme
    defaultBtn: {
      borderRadius: "45px",
      fontSize: ["14px", 1, null, null, 2],
      letterSpacings: ["-0.5px", null, null, null, "-0.15px"],
      padding: ["11px 20px 10px", null, null, null, "13px 30px"],
      fontFamily: "body",
      cursor: "pointer",
      lineHeight: 1.2,
      transition: "all 0.25s",
      fontWeight: 500,
      "&:focus": {
        outline: 0,
      },
    },
    primary: {
      variant: "buttons.defaultBtn",
      color: "white",
      bg: "primary",
      "&:hover": {
        boxShadow: "rgba(31, 62, 118, 0.57) 0px 9px 20px -5px",
      },
    },
    whiteButton: {
      variant: "buttons.defaultBtn",
      color: "heading_on_white",
      bg: "white",
      "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.5) 0px 12px 24px -10px",
      },
    },
    secondary: {
      variant: "buttons.defaultBtn",
      border: "1.5px solid white",
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        bg: "white",
        color: "heading_secondary",
      },
    },
    textButton: {
      variant: "buttons.defaultBtn",
      backgroundColor: "transparent",
      color: "text",
      display: "flex",
      alignItems: "center",
      svg: {
        fontSize: [4, 6],
        mr: 2,
      },
    },
  },

  styles: {
    // To add base, top-level styles to the <body> element, use theme.styles.root.
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSmoothing: "antialiased",
      ".modal-video-close-btn": {
        cursor: "pointer",
        top: "-25px",
        right: "-25px",
        width: " 25px",
        height: "25px",
      },
      ".modal-video-movie-wrap": {
        margin: 6,
        width: "auto",
      },
    },
    // Divider styles
    hr: {
      border: 0,
      borderBottom: "1px solid",
      borderColor: "#D9E0E7",
    },
    // also you can use other HTML elements style here
    ul: {
      listStyle: "none",
    },
    srOnly: {
      border: "0 !important",
      clip: "rect(1px, 1px, 1px, 1px) !important",
      clipPath: "inset(50%) !important",
      height: "1px !important",
      margin: "-1px !important",
      overflow: "hidden !important",
      padding: "0 !important",
      // @ts-ignore
      position: "absolute !important",
      width: "1px !important",
      // @ts-ignore
      whiteSpace: "nowrap !important",
    },
  },
};
