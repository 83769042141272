import { Theme } from "theme-ui";
import { commonTheme } from "./common";

export const landingTheme: Theme = {
  ...commonTheme,
  // @ts-ignore
  section: {
    keyFeature: {
      py: [8, null, 9, null, null, 10],
    },
    feature: {
      py: [8, null, 9, null, null, 10],
    },
    partner: {
      pt: [2, null, null, 5],
      pb: [8, null, 9, null, null, 10],
    },
    testimonial: {
      py: [8, null, 9, null, null, 10],
      overflow: "hidden",
    },
    securePayment: {
      overflow: "hidden",
      position: "relative",
      pt: 9,
    },
    faq: {
      py: [8, null, 9, null, null, 10],
    },
    general: {
      p: [3, null, null, 5, null, 10],
    },
  },
  sectionHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: -1,
    marginBottom: ["50px", null, "60px", null, "65px", "75px"],
    title: {
      fontSize: ["24px", null, "28px", null, null, "32px", null, "36px"],
      color: "heading_secondary",
      lineHeight: [1.3, null, null, 1.2],
      textAlign: "center",
      fontWeight: "700",
      letterSpacing: "-.5px",
    },

    subTitle: {
      fontSize: [0, null, "14px"],
      color: "heading",
      textAlign: "center",
      letterSpacing: ["1.5px", null, "2px"],
      textTransform: "uppercase",
      fontWeight: "700",
      mb: "8px",
      lineHeight: 1.5,
    },
  },
  sectionText: {
    fontSize: ["16px", null, "18px", null, null, "20px", null, "22px"],
    color: "text",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: 1.2,
    mb: "16px",
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      letterSpacing: "heading",
      color: "heading",
    },
    heroPrimary: {
      color: "white",
      fontSize: ["30px", "38px", "40px", "44px", "50px"],
      lineHeight: 1.2,
      fontWeight: 700,
      mb: [5, null, null, null, "30px"],
    },
    heroSecondary: {
      color: "white",
      fontSize: ["18px", "24px", "28px", "32px", "36px"],
      lineHeight: 1.2,
      fontWeight: 500,
      mb: [5, null, null, null, "30px"],
    },
    heroTertiary: {
      color: "white",
      fontSize: [2, 3, 4, "17px", null, 3, "19px", 4],
      lineHeight: 1.1,
      fontWeight: "body",
      pr: [0, null, null, null, null, "100px", null, "125px"],
      mb: ["35px", null, null, null, "40px", null, null, 7],
    },
    title: {
      // extends the text.heading styles
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: 18,
      lineHeight: "30px",
      color: "#0F2137",
    },
    lead: {
      fontSize: 40,
      fontFamily: "DM Sans",
      fontWeight: "500",
      lineHeight: "60px",
      letterSpacing: "-1.5px",
      color: "#0F2137",
    },
    muted: {
      lineHeight: "26px",
      color: "muted",
    },
    secondary: {
      fontWeight: 500,
      color: "#00A99D",
      lineHeight: "40px",
    },
  },
  links: {
    default: {
      cursor: "pointer",
    },
    bold: {
      fontWeight: "bold",
    },
    nav: {
      display: ["none", null, "inline-block"],
      px: 25,
      fontWeight: "normal",
    },
    footer: {
      display: "block",
      px: 0,
      color: "inherit",
      textDecoration: "none",
    },
    logo: {
      display: "flex",
    },
  },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 99999,
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
    },
    offer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: ["1 1 calc(50% - 16px)", "1 1 20%"],
      minHeight: 130,
      m: 2,
      background: "#FFFFFF",
      border: "1px solid #EDEFF6",
      borderRadius: 5,
    },
    featureCard: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
      p: 3,
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: "bold",
    },
    input: {
      borderRadius: 8,
      borderColor: "border_color",
      height: 60,
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors?.primary}`,
        outline: "none",
      },
    },
  },

  badges: {
    primary: {
      color: "background",
      bg: "#28A5FF",
      borderRadius: 30,
      p: "3px 11px",
      fontSize: 1,
      letterSpacing: "-0.5px",
    },
    outline: {
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 0 1px",
    },
  },
};
