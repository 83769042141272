import { useMedia } from "hooks/media-queries";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    maxWidth: 520,
    maxHeight: "90%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
  overlay: {
    // Appear over header.
    zIndex: 10000,
  },
};

const modalStylesSmall = {
  content: {
    top: "50%",
    left: "0%",
    maxWidth: 520,
    maxHeight: "90%",
    right: "auto",
    bottom: "auto",
    transform: "translate(0%, -50%)",
    padding: 0,
  },
  overlay: {
    // Appear over header.
    zIndex: 10000,
  },
};

export const useModalStyle = () => {
  const { isTabletMid } = useMedia();
  return isTabletMid ? modalStylesSmall : modalStyles;
};
