import { useRouter } from "next/router";

export const getParamsIdx = (urlPath: string) => {
  return Math.min.apply(Math, [
    urlPath.indexOf("?") > 0 ? urlPath.indexOf("?") : urlPath.length,
    urlPath.indexOf("#") > 0 ? urlPath.indexOf("#") : urlPath.length,
  ]);
};

export const useParamsSuffix = () => {
  const router = useRouter();
  const urlPath = router.asPath;
  const paramsIdx = getParamsIdx(urlPath);
  return urlPath.substring(paramsIdx);
};
