import { imageURL } from "assets";
import Image from "next/image";
import { logEvent } from "analytics";

const PlayStoreBadge = ({ eventSuffix }: { eventSuffix: string }) => {
  return (
    <a
      className="badge-link"
      href="/android"
      target="_blank"
      rel="noreferrer"
      style={{ margin: 5 }}
      onClick={() => {
        logEvent("Conversion", "play_store_badge");
        logEvent("Button", `play_store_badge_${eventSuffix}`);
      }}
    >
      <Image
        src={imageURL("google-play-badge.svg")}
        width={202.5}
        height={60}
        alt="oneCoach Android app"
      />
    </a>
  );
};

export default PlayStoreBadge;
