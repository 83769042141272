import { imageURL } from "assets";
import Image from "next/image";
import { logEvent } from "analytics";

const AppStoreBadge = ({ eventSuffix }: { eventSuffix: string }) => {
  return (
    <a
      className="badge-link"
      href="/ios"
      target="_blank"
      rel="noreferrer"
      style={{ margin: 5 }}
      onClick={() => {
        logEvent("Conversion", "app_store_badge");
        logEvent("Button", `app_store_badge_${eventSuffix}`);
      }}
    >
      <Image
        src={imageURL("app-store-badge.svg")}
        width={202.5}
        height={60}
        alt="oneCoach iOS app"
      />
    </a>
  );
};

export default AppStoreBadge;
